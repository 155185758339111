import React, { useContext } from 'react'
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListadoRecursos from '../components/ListadoRecursos';

const Recursos = () => {

    const { setOpenAlta, setFormAlta, buscarRecurso, setBuscarRecurso } = useContext(ContextEstados);

    const Alta = () => {
        setFormAlta(16);
        setOpenAlta(true)
    }

    return (
        <Layout
            pagina="Recursos Mecánicos"
        >
            <div className="w-full flex-1 bg-black">
                <h1 className="mt-10 mb-5 text-white text-3xl text-center">Recursos Mecánicos</h1>
                <div className='w-full mt-2 bg-white'>
                    <div className='w-full h-16 py-2 px-10 bg-slate-400 flex'>
                        <button
                            className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
                            onClick={() => Alta(true)}
                        >
                            <AiOutlinePlus className='text-2xl mr-2' />
                            Agregar Recurso
                        </button>
                        <div className='py-3 rounded-3xl bg-white w-1/3 mx-auto px-3 flex'>
                            <input
                                type="text"
                                className='w-11/12 outline-none'
                                value={buscarRecurso}
                                placeholder="Buscar Recurso"
                                onChange={(e) => setBuscarRecurso(e.target.value)}
                            />
                            <AiOutlineSearch className='ml-auto text-2xl' />
                        </div>
                    </div>
                    <div className='w-full h-screen p-10 bg-slate-200 overflow-scroll'>
                        <ListadoRecursos />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Recursos