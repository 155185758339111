import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import Select from 'react-select';
import ContextEstados from '../context/Estados';
import { FaPlay, FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';
import Tippy from '@tippyjs/react';


const ListadoOrdenes = () => {
    const [ordenes, setOrdenes] = useState([]);
    const [equipo, setEquipo] = useState('');
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [estadoOt, setEstadoOt] = useState(0)

    const { idOrden,
        estadoOrden,
        notification,
        setOrdenSelected,
        setActividadSelected,
        setSolicitudSelected,
        setTipoModal,
        setVistaOrdenes,
        setOpenVista,
        idUsuario,
        roles,
        tipoUsrLogin,
        sucursales,
        idViewOt, setIdViewOt } = useContext(ContextEstados);

    useEffect(() => {
        const consultaOrdenes = async () => {
            const formData = new FormData()
            formData.append('estado', estadoOrden);
            formData.append('tipousuario', tipoUsrLogin);
            formData.append('idusuario', idUsuario);
            formData.append('equipo', equipo)
            formData.append('fechaInicio', fechaInicio)
            formData.append('fechaFin', fechaFin)
            formData.append('estadoOt', estadoOt)
            try {
                const resultado = await axios({
                    url: Url + 'api/ordenesList.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(resultado)
                setOrdenes(resultado.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaOrdenes()
    }, [idOrden, estadoOrden, idUsuario, notification, equipo, fechaInicio, fechaFin, estadoOt])


    const asignarOrden = (id) => {
        ordenes.forEach(element => {
            if (element.id === id) {
                if (element.idactividad) {
                    setActividadSelected({
                        id: element.idactividad
                    })
                }
                else {
                    setSolicitudSelected({
                        id: element.idsolicitud
                    })
                }
                setOrdenSelected(element)
                return
            }
        });
    }

    const verOrden = async (id) => {
        asignarOrden(id);
        setTipoModal(30)
        setOpenVista(true);
    }
    const editarOrden = async (id) => {
        asignarOrden(id);
        setVistaOrdenes(1)
    }
    const eliminarOrden = async (id) => {
        asignarOrden(id);
        setTipoModal(31)
        setOpenVista(true);
    }

    useEffect(() => {
        if (idViewOt !== 0)
            verOrden(idViewOt)
    }, [idViewOt])

    const handleSelect = (val) => {
        setEquipo(val.id)
    }

    return (
        <div>
            <div className='w-full my-5 shadow-xl border border-gray-50 rounded-xl p-5'>
                <h3 className='text-center text-gray-500 uppercase'>Filtros</h3>
                <div className='w-full mt-5 flex'>
                    <div className='w-1/3 p-3 text-gray-500 uppercase'>
                        <h4 className='text-center'>Equipo / Activo</h4>
                        <input
                            type='text'
                            className='p-3 rounded-full border border-gray-200 w-full text-gray-600'
                            value={equipo}
                            onChange={(e) => setEquipo(e.target.value)}
                        />
                    </div>
                    <div className='w-1/3 p-3 text-gray-500 uppercase'>
                        <h4 className='text-center'>Fecha de Programación</h4>
                        <div className='flex w-full'>
                            <div className='w-1/2 text-gray-500 uppercase'>
                                <input
                                    type='date'
                                    className='p-3 w-11/12 mx-auto rounded border border-gray-200'
                                    value={fechaInicio}
                                    onChange={(e => setFechaInicio(e.target.value))}
                                />
                            </div>
                            <div className='w-1/2 text-gray-500 uppercase'>
                                <input
                                    type='date'
                                    className='p-3 w-11/12 mx-auto rounded border border-gray-200'
                                    value={fechaFin}
                                    onChange={(e => setFechaFin(e.target.value))}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='w-1/3 p-3 text-gray-500 uppercase'>
                        <h4 className='text-center'>Estado</h4>
                        <select
                            className='p-3 w-11/12 mx-auto rounded border border-gray-200 bg-white'
                            id='sActividad'
                            onChange={(e) => setEstadoOt(e.target.value)}
                            value={estadoOt}
                        >
                            <option value='0'>Estado...</option>
                            <option value='1'>Abierta</option>
                            <option value='2'>Iniciada</option>
                            <option value='3'>Espera de Fin</option>
                            <option value='6'>Suspendida</option>
                        </select>

                    </div>
                </div>
            </div>
            <table className='table-auto w-full border border-collapse border-spacing-5 border-slate-100 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Nº OT</th>
                        <th className='border-gray-400 p-2'>Equipo</th>
                        <th className='border-gray-400 p-2'>Solicitud / Planeamiento</th>
                        <th className='border-gray-400 p-2'>Trabajo a Realizar</th>
                        <th className='border-gray-400 p-2'>Fecha prog.</th>
                        <th className='border-gray-400 p-2'>Estado</th>
                        <th className='border-gray-400 p-2'>Unidad</th>
                        <th className='border-gray-400 p-2'>Tipo</th>
                        <th className='border-gray-400 p-2'>Prioridad</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof ordenes !== 'undefined' && (
                            ordenes.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={`hover:bg-slate-200 border-gray-200 hover:cursor-pointer ${index % 2 === 0 ? 'bg-white' : 'bg-slate-100'}`}
                                    onClick={() => asignarOrden(item.id)}
                                >
                                    <td onClick={() => verOrden(item.id)} className='border-gray-200 p-1 text-center font-bold'>{item.numero}</td>
                                    <td onClick={() => verOrden(item.id)} className='border-gray-200 p-1 text-center'>{item.equipoact ? (item.equipoact) : (item.equiposol)}</td>
                                    <td onClick={() => verOrden(item.id)} className='border-gray-200 p-1 text-left'>{item.actividad ? (item.actividad) : (item.solicitud)}</td>
                                    <td onClick={() => verOrden(item.id)} className='border-gray-200 p-1 text-left'>{item.detalletrabajo}</td>
                                    <td onClick={() => verOrden(item.id)} className='border-gray-200 p-1 text-center'>{item.fechaprog}</td>
                                    <td onClick={() => verOrden(item.id)} className={`border-gray-400 p-1 text-center ${item.idestado === '1' ? 'text-white bg-orange-300 ' : item.idestado === '2' ? ' bg-yellow-300 ' : item.idestado === '3' ? 'text-white bg-red-300 ' : item.idestado === '6' ? 'bg-cyan-300' : ' bg-green-300 '}`}>{item.estado}</td>
                                    <td onClick={() => verOrden(item.id)} className='border-gray-400 p-1 text-center'>{item.unidad}</td>
                                    <td onClick={() => verOrden(item.id)} className='border-gray-400 p-1 text-center'>{item.tipo}</td>
                                    <td onClick={() => verOrden(item.id)} className='border-gray-400 p-1 text-center'>{item.prioridad}</td>
                                    <td className='p-1 flex justify-center'>
                                        {(estadoOrden === 1 && (roles.includes("1") || roles.includes("26"))) && (
                                            <>
                                                {
                                                    (tipoUsrLogin === '2' || tipoUsrLogin === '4') ? (
                                                        <Tippy
                                                            content={<span className='bg-gray-600 text-white p-2 rounded-md'>Procesar OT</span>}
                                                        >
                                                            <button
                                                                className='bg-green-400 p-3 rounded-md text-white ml-3'
                                                                onClick={() => editarOrden(item.id)}
                                                            >
                                                                <FaPlay />
                                                            </button>
                                                        </Tippy>

                                                    ) : (
                                                        <Tippy
                                                            content={<span className='bg-gray-600 text-white p-2 rounded-md'>Editar OT</span>}
                                                        >
                                                            <button
                                                                className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                                onClick={() => editarOrden(item.id)}
                                                            >
                                                                <FaPen />
                                                            </button>
                                                        </Tippy>
                                                    )
                                                }
                                                {(tipoUsrLogin !== '2') && (
                                                    <Tippy
                                                        content={<span className='bg-gray-600 text-white p-2 rounded-md'>Eliminar OT</span>}
                                                    >
                                                        <button
                                                            className='bg-red-500 p-3 rounded-md text-white ml-3'
                                                            onClick={() => eliminarOrden(item.id)}
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </Tippy>

                                                )}
                                            </>
                                        )}
                                    </td>
                                </tr>

                            ))
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListadoOrdenes