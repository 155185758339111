import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListadoUsuarios from '../components/ListadoUsuarios';
import { RiFileExcel2Line } from 'react-icons/ri'

const AdministracionUsuarios = () => {
	const { setOpenAlta, setFormAlta, buscarUsuario, setBuscarUsuario, setTipoUsuario, roles } = useContext(ContextEstados);
	
	const mail = localStorage.getItem('sgpp_m');
	const navigate = useNavigate();

	const Alta = () => {
		setTipoUsuario(0);
		setFormAlta(2);
		setOpenAlta(true);
	}
	useEffect (()=>{
		if (!roles.includes("1")) {
		navigate("/", { replace: true });
		}
	},[mail])
	const Import = () => {
		setFormAlta(12);
		setOpenAlta(true);
	}


	return (
		<Layout 
		pagina="Usuarios"
		>
			<div className="w-full flex-1 bg-black">
				<h1 className="mt-10 mb-5 text-white text-3xl text-center">Administración de Usuarios</h1>
				<div className='w-full h-screen mt-2 bg-white'>
					<div className='w-full h-16 py-2 px-10 bg-slate-400 flex'>
						<button 
							className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
							onClick={() => Alta(true)}
						>
							<AiOutlinePlus className='text-2xl mr-2'/>
							Agregar Usuario
						</button>
						<button 
							className='bg-green-600 hover:bg-green-400 shadow-lg ml-5 p-3 rounded-md text-white uppercase flex'
							onClick={() => Import()}
						>
							<p className='text-xl mr-2'>
								<RiFileExcel2Line/>
							</p>
							Importar
						</button>
						<div className='py-3 rounded-3xl bg-white w-1/3 mx-auto px-3 flex'>
							<input
								type="text"
								className='w-11/12 outline-none'
								value={buscarUsuario}
								placeholder="Buscar Usuario"
								onChange={(e)=>setBuscarUsuario(e.target.value)}
							/>
							<AiOutlineSearch className='ml-auto text-2xl'/>
						</div>
					</div>
					<div className='w-full h-full p-10 bg-slate-200'>
						<ListadoUsuarios
						tipoUsuarios={0}
					/>
					</div>
				</div>
			</div>       
		</Layout>
	)
}

export default AdministracionUsuarios