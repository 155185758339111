import { useContext } from 'react'
import authContext from '../context/auth/authContext';
import ContextEstados from '../context/Estados';
import { useFormik } from "formik"
import * as Yup from "yup"
import Alerta from '../components/Alerta';
import Layout from '../components/Layout';
import axios from 'axios'
import { Url } from '../components/Url';

const Perfil = () => {
    const { setMensaje } = useContext(ContextEstados);

    const mail = localStorage.getItem('sgpp_m');
    const idusuario = localStorage.getItem('sgpp_id');


    const formik = useFormik({
        initialValues:{
            passwordPrev: '',
            passwordNew: '',
            password2: ''
        },
        validationSchema: Yup.object({
            passwordPrev: Yup.string()
                    .required("Debe ingresar un Password")
                    .min(6,"Debe contar con un mínimo de 6 caracteres"),
            passwordNew: Yup.string()
                    .required("Debe ingresar un Password")
                    .min(6,"Debe contar con un mínimo de 6 caracteres"),
            password2: Yup.string()
                    .required("Reingrese la Contraseña")
                    .oneOf([Yup.ref('passwordNew')], 'Las contraseñas no coinciden.')        }),
            onSubmit: async (valores,{resetForm}) => {
                const formData=new FormData()
                
                formData.append('idusuario',idusuario);
                formData.append('mail',mail);
                formData.append('passwordPrev',valores.passwordPrev);
                formData.append('password',valores.passwordNew);
                try{
                    const response = await axios({
                        url: Url+'api/changePassword.php',
                        method: 'POST',
                        data: formData,
                    })
                    if (response.data===0)
                    {
                        setMensaje("La contraseña fue modificada con éxito!")
                        resetForm({ values: ''});
                    }
                    else {
                        setMensaje("La contraseña actual no es correcta!")
                    }        
                } catch (e) {
                    console.log(e)
                }                
            }
    });
    

    return (
        <Layout
            pagina="Mi perfil"
        >
            <div className="w-full flex-1 bg-black">
				<h1 className="mt-10 mb-5 text-white text-3xl text-center">Perfil de Usuario</h1>
				<div className='w-full bg-white h-screen pt-5'>
                    <Alerta tipo={"success"}/>
                    <div
                        className='md:w-1/3 w-8/12 mx-auto p-5 bg-slate-400 rounded-2xl'
                    >
                        <form
                            
                            onSubmit={formik.handleSubmit}
                        >
                            <h1 className='text-white text-2xl'>Modificar contraseña</h1>
                            <div className="transition-all w-full pt-5 mt-5">
                                <div className="sm:items-start mt-5">
                                    <label htmlFor="passwordPrev" className="block text-white uppercase font-bold">Contraseña Anterior</label>
                                    <input 
                                        className="p-3 w-full rounded" 
                                        id="passwordPrev" 
                                        placeholder="Ingrese su Contraseña actual" 
                                        type="password" 
                                        value={formik.values.passwordPrev}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    { formik.touched.passwordPrev && formik.errors.passwordPrev ? (
                                        <div className='w-full block mb-2 text-red-500'>
                                            <p>{formik.errors.passwordPrev}</p>
                                        </div>
                                    ) : null }
                                </div>
                                <div className="sm:items-start mt-5">
                                    <label htmlFor="passwordNew" className="block text-white uppercase font-bold">Nueva Contraseña</label>
                                    <input 
                                        className="p-3 w-full rounded" 
                                        id="passwordNew" 
                                        placeholder="Ingrese una Contraseña nueva" 
                                        type="password" 
                                        value={formik.values.passwordNew}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    { formik.touched.passwordNew && formik.errors.passwordNew ? (
                                        <div className='w-full block mb-2 text-red-500'>
                                            <p>{formik.errors.passwordNew}</p>
                                        </div>
                                    ) : null }
                                </div>
                                <div className="sm:items-start mt-5">
                                    <label htmlFor="password2" className="block text-white uppercase font-bold">Confirmar Contraseña</label>
                                    <input 
                                        className="p-3 w-full rounded" 
                                        id="password2" 
                                        placeholder="Confirme su nueva Contraseña" 
                                        type="password" 
                                        value={formik.values.password2}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    { formik.touched.password2 && formik.errors.password2 ? (
                                        <div className='w-full block mb-2 text-red-500'>
                                            <p>{formik.errors.password2}</p>
                                        </div>
                                    ) : null }
                                </div>
                            </div>
                            <div className="pt-10">
                                <div className="w-full rounded-lg">
                                    <input
                                        type="submit"
                                        className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                                        value="Modificar Contraseña"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Perfil